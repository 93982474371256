<template>
  <div>
    <CSTabBar
        :checkedTab="checkedTab"
        :tabbar="orderState"
        @changeTabBar="changeTabBar"
    />
    <div class="filter-panel">
      <input
          v-model="search"
          class="cs-input"
          placeholder="搜索车牌/姓名/手机号"
          type="text"
      />
      <button class="btn btn-primary" @click="queryBindList()">查询</button>
    </div>

    <div class="result-panel">
      <CSTable v-if="checkedTab === 1" :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>绑定时间</th>
            <th>姓名</th>
            <th>昵称</th>
            <th>手机号</th>
            <th>绑定车牌</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="parkingPlates.length > 0">
            <tr v-for="plate in parkingPlates" :key="plate.id">
              <td>{{ plate.time || "-" }}</td>
              <td>{{ plate.name || "-" }}</td>
              <td>{{ plate.nickName || "-" }}</td>
              <td>{{ plate.phone || "-" }}</td>
              <td>{{ plate.carNumber || "-" }}</td>
              <td>
                <!-- <button @click="unbindPlate(plate.id, plate.carNumber)" class="btn btn-primary">解绑</button> -->
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul
                      class="dropdown-menu"
                      style="min-width: 76px; width: 76px; padding: 0"
                  >
                    <li
                        @click="unbindPlate(plate.id, plate.carNumber)"
                    >
                      <a style="width: 100%; padding: 3px 0">
                        解绑
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <CSTable v-else :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>解绑时间</th>
            <th>姓名</th>
            <th>昵称</th>
            <th>手机号</th>
            <th>解绑车牌</th>
            <th>解绑人</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="parkingPlates.length > 0">
            <tr v-for="plate in parkingPlates" :key="plate.id">
              <td>{{ plate.time || "-" }}</td>
              <td>{{ plate.name || "-" }}</td>
              <td>{{ plate.nickName || "-" }}</td>
              <td>{{ plate.phone || "-" }}</td>
              <td>{{ plate.carNumber || "-" }}</td>
              <td>{{ plate.unbindOperator || "-" }}</td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination/>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import CSTabBar from "@/components/common/CSTabBar";
import {parkingPlateBindUrl, unbindPlateUrl} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
  name: "LicensePlate",
  components: {CSTable, CSTabBar, Pagination},
  data() {
    return {
      orderState: {
        2: "车牌解绑记录",
        1: "车牌绑定管理",
      },
      checkedTab: 1,
      filterHeight: 0,
      search: "",
      parkingPlates: [],
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    document.body.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.queryBindList();
      }
    });
    this.$vc.on(
        this.$route.path,
        "pagination_page",
        "event", page => {
          this.pageParams.pageNo = page;
          this.queryBindList(page)
        });
    this.queryBindList();
  },
  mounted() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  updated() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
  },
  methods: {
    changeTabBar(val) {
      this.checkedTab = val;
      this.queryBindList();
    },
    /**
     * 查询绑定的列表
     * @param {Number} pageNo 页码
     * @param {Number} pageSize 查询数量
     * */
    queryBindList(pageNo = this.pageParams.pageNo, pageSize = 10) {
      const state = {
        1: 1,
        2: 0,
      };
      this.$fly
          .post(parkingPlateBindUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            search: this.search,
            state: state[this.checkedTab],
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code === 0) {
              this.parkingPlates = res.data.datas ?? [];
              if (pageNo === 1) {
                this.$vc.emit(this.$route.path, "pagination", "init", {
                  total: res.data.total,
                  pageSize,
                  currentPage: pageNo,
                });
              }
            }
          });
    },
    /**
     * 解绑车牌
     * @param {Number} id 车牌id
     * @param {String} plateNumber 车牌号
     * */
    unbindPlate(id, plateNumber) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定解绑吗？解绑后，其它用户可与该车牌${plateNumber}绑定。`,
        onConfirm: () => {
          this.$fly
              .get(unbindPlateUrl, {
                id,
              })
              .then((res) => {
                if (res.code === 0) {
                  this.$vc.toast("解绑成功");
                  this.$CSDialog.instance.closeDialog();
                  this.queryBindList();
                }
              });
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
